<template>
  <SdPage id="property-units-page">
    <SdLoadingLayer :init="init">
      <template v-slot:loaded>
        <div class="row text-gray-darker mb-5 position-relative">
          <router-link
            :to="{ name: 'app.properties' }"
            class="col-auto back-action"
          >
            <img
              src="@/assets/back-arrow.svg"
            >
          </router-link>
          <div class="col-auto">
            <div
              class="picture"
              :style="thumbnailUrl ? `background-image: url(${thumbnailUrl})` : ''"
            />
          </div>
          <div class="col align-self-center font-28 line-height-28">
            <div class="font-weight-strong">
              {{ property.short_address }}
            </div>
            <div class="mt-2">
              {{ property.units.length }} Units
            </div>
          </div>
          <div class="col-auto align-self-end ml-auto">
            <SdActionButtons
              v-if="actions.addUnit"
              :actions="actions"
              @toggle-action-ui-flag="uiFlags.isAddUnitDialogVisible = true"
            />
          </div>
        </div>
        <div class="row">
          <div
            v-for="unit in property.units"
            :key="unit.id"
            class="col-lg-3 col-md-4 col-12"
          >
            <router-link
              class="unit"
              :to="{ name: 'app.units.show', params: { id: unit.property_id, unit_id: unit.id } }"
              @click.native.capture="linkClicked($event, unit)"
            >
              <div
                class="header"
                :style="getUnitCardStyle(unit)"
              >
                <div
                  v-if="unit.active_showing_profile"
                  class="time-in-market"
                >
                  <div>
                    <strong>{{ unit.time_in_market }}</strong>
                    <span>
                      Active {{ $pluralize('day', unit.time_in_market) }}
                    </span>
                  </div>
                </div>
                <div class="actions">
                  <ElDropdown
                    :ref="`actionsDropDown-${unit.id}`"
                    trigger="click"
                    hide-on-click
                    class="pointer"
                    @click.native.prevent
                  >
                    <div>
                      <i class="sdicon-more-vert" />
                    </div>
                    <ElDropdownMenu>
                      <ElDropdownItem
                        v-if="!$store.getters['Auth/accountIsInactive']"
                        @click.native="showBookAServiceDialog(unit)"
                      >
                        Book a service
                      </ElDropdownItem>
                      <ElDropdownItem
                        v-if="unit.active_showing_profile"
                        @click.native="showAddInquiryDialog(unit)"
                      >
                        Add inquiry
                      </ElDropdownItem>
                      <router-link
                        v-if="uiFlags.isSingle"
                        :to="{
                          name: 'app.units.show',
                          params: { id: property.id, unit_id: unit.id },
                          query: { tab: 'listing' },
                        }"
                      >
                        <ElDropdownItem>
                          Edit
                        </ElDropdownItem>
                      </router-link>
                      <ElDropdownItem
                        v-if="computeIsShowingProfileNotPublishListing(unit) && get(unit, 'active_showing_profile.paused_until') === null"
                        @click.native="showUnitPauseDialog(unit, false)"
                      >
                        Pause showing
                      </ElDropdownItem>
                      <template v-if="get(unit.active_showing_profile, 'paused_until')">
                        <ElDropdownItem @click.native="showUnitUnpauseDialog(unit)">
                          Unpause showing
                        </ElDropdownItem>
                        <ElDropdownItem @click.native="showUnitPauseDialog(unit, true)">
                          Update pause end time
                        </ElDropdownItem>
                      </template>
                      <ElDropdownItem
                        v-if="unit.active_showing_profile"
                        @click.native="showUnitStopDialog(unit)"
                      >
                        Stop showing
                      </ElDropdownItem>
                      <ElDropdownItem
                        v-if="!unit.active_showing_profile && property.units.length > 1 && !uiFlags.isIntegrationEnabled"
                        @click.native="showUnitDeleteDialog(unit)"
                      >
                        Delete
                      </ElDropdownItem>
                    </ElDropdownMenu>
                  </ElDropdown>
                </div>
              </div>
              <div class="body">
                <div class="font-weight-bold">
                  {{ unit.name }}
                </div>
                <div
                  v-if="unit.active_lease_period"
                  class="inquiries-tours-count"
                >
                  <UnitActivityCounters :unit="unit" />
                </div>
              </div>

              <div class="footer">
                <template v-if="unit.active_showing_profile && unit.active_showing_profile.paused_until">
                  <div class="status paused mr-2">
                    Paused
                  </div>
                  <i
                    v-if="unit.status === 'auto_paused'"
                    class="sdicon-exclamation-triangle text-orange mr-1"
                  />
                  <span class="font-11 text-danger">
                    {{ getUnitPausedLabel(unit, property.timezone) }}
                  </span>
                </template>
                <template v-else-if="unit.active_showing_profile">
                  <ElTooltip
                    v-if="get(unit, 'active_lease_period.has_identity_verification')"
                    popper-class="action-popper-class"
                    placement="top"
                  >
                    <template slot="content">
                      <p class="text-center">
                        AI Identity Verification Enabled
                      </p>
                    </template>
                    <i class="sdicon-fingerprint text-success mr-1 font-21" />
                  </ElTooltip>
                  <div class="status active">
                    {{ computeActiveStatusText(unit) }}
                  </div>
                  <span
                    v-if="unit.active_showing_profile.is_self_showing"
                    class="black-tag"
                  >
                    <i class="sdicon-key position-absolute self-showing-key" />
                    <span class="pl-4 text-gray-dark">SELF SHOWING</span>
                  </span>
                  <span
                    v-else-if="computeIsShowingProfileNotPublishListing(unit)"
                    class="upcoming-tours"
                  >
                    {{ computedUpcoming(unit) }}
                  </span>
                </template>
                <template v-else>
                  <div class="status inactive">
                    Not Showing
                  </div>
                  <div
                    v-if="property.imported_at"
                    class="font-13 text-gray-darker imported-at"
                  >
                    {{ resolveImportedType() }}
                  </div>
                </template>
              </div>
            </router-link>
          </div>
        </div>
      </template>
    </SdLoadingLayer>

    <AddUnitDialog
      v-if="uiFlags.isAddUnitDialogVisible && property"
      :property.sync="property"
      :visible="uiFlags.isAddUnitDialogVisible"
      @close="addUnitDialogClosed"
    />
    <UnitAddInquiryDialog
      v-if="uiFlags.isAddInquiryDialogVisible"
      :unit="dialogProperties.unit"
      @close="addInquiryDialogClosed"
    />
    <ServicesDialog
      v-if="uiFlags.isBookAServiceDialogVisible"
      :unit="dialogProperties.unit"
      :property="property"
      @close="uiFlags.isBookAServiceDialogVisible = false"
    />
    <UnitPauseDialog
      v-if="dialogProperties.unit && dialogProperties.unit.active_showing_profile"
      :unit="dialogProperties.unit"
      :is-edit="dialogProperties.isUnitPauseEdit"
      :visible="uiFlags.isUnitPauseDialogVisible"
      @close="pauseDialogClosed"
    />
    <UnitUnpauseDialog
      v-if="dialogProperties.unit && dialogProperties.unit.active_showing_profile"
      :unit="dialogProperties.unit"
      :visible="uiFlags.isUnitUnpauseDialogVisible"
      @close="unpauseDialogClosed"
    />
    <UnitStopDialog
      v-if="dialogProperties.unit && dialogProperties.unit.active_showing_profile"
      :unit="dialogProperties.unit"
      :visible="uiFlags.isUnitStopDialogVisible"
      @close="stopDialogClosed"
    />
    <PropertyRentedDialog
      v-if="uiFlags.isPropertyRentedDialogVisible && dialogProperties.lastShowingProfileId"
      :title="`${dialogProperties.unit.property.short_address}${dialogProperties.unit.name ? ` - ${dialogProperties.unit.name}` : ''}`"
      :first-time-opened="true"
      :is-rented="dialogProperties.isRented"
      :showing-profile-id="dialogProperties.lastShowingProfileId"
      :visible.sync="uiFlags.isPropertyRentedDialogVisible"
      @close="propertyRentedDialogClosed"
    />
    <MoveInConditionReportPrompt
      v-if="dialogProperties.unit"
      :visible="uiFlags.isMoveInConditionReportPromptVisible"
      :unit="dialogProperties.unit"
      @close="uiFlags.isMoveInConditionReportPromptVisible = false"
    />
    <UnitDeleteDialog
      v-if="dialogProperties.unit && !dialogProperties.unit.active_showing_profile"
      :unit="dialogProperties.unit"
      :visible="uiFlags.isUnitDeleteDialogVisible"
      @close="unitDeleteDialogClosed"
    />
  </SdPage>
</template>

<script>
import UnitAddInquiryDialog from '@/components/unit/UnitAddInquiryDialog';
import ServicesDialog from '@/components/service/ServicesDialog';
import UnitPauseDialog from '@/components/unit/UnitPauseDialog';
import UnitUnpauseDialog from '@/components/unit/UnitUnpauseDialog';
import UnitStopDialog from '@/components/unit/UnitStopDialog';
import PropertyRentedDialog from '@/components/property/PropertyRentedDialog';
import MoveInConditionReportPrompt from '@/components/unit/MoveInConditionReportPrompt';
import UnitDeleteDialog from '@/components/unit/UnitDeleteDialog';
import UnitActivityCounters from '@/components/unit/UnitActivityCounters.vue';
import AddUnitDialog from '@/components/unit/AddUnitDialog';
import pluralize from 'pluralize';
import get from 'lodash.get';
import momentUtil from '@/utils/MomentUtil';
import { computed, reactive, ref } from '@vue/composition-api';
import { showErrorNotify, showSuccessNotify } from '@/utils/NotifyUtil';
import { resolvePictureUrl } from '@/utils/PropertyUtil';
import Showing from '@/constants/Showing';
import { getUnitFirstImage, getUnitPausedLabel } from '@/utils/UnitUtil';
import { getBusinessSourceType } from '@/utils/BusinessSourceUtil';
import { capitalizeFirstLatter } from '@/utils/StringUtil';

export default {
  name: 'PropertyUnits',
  components: {
    UnitActivityCounters,
    MoveInConditionReportPrompt,
    UnitDeleteDialog,
    PropertyRentedDialog,
    UnitStopDialog,
    UnitUnpauseDialog,
    UnitPauseDialog,
    ServicesDialog,
    UnitAddInquiryDialog,
    AddUnitDialog,
  },
  setup(props, context) {
    const {
      $store,
    } = context.root;
    const property = ref(null);
    const uiFlags = reactive({
      isAddUnitDialogVisible: false,
      isBookAServiceDialogVisible: false,
      isAddInquiryDialogVisible: false,
      isUnitPauseDialogVisible: false,
      isUnitUnpauseDialogVisible: false,
      isUnitStopDialogVisible: false,
      isPropertyRentedDialogVisible: false,
      isMoveInConditionReportPromptVisible: false,
      isUnitDeleteDialogVisible: false,
      isIntegrationEnabled: $store.getters['BusinessSource/isIntegrationEnabled'],
      isLegacyIntegrationEnabled: $store.getters['BusinessSource/isLegacyIntegrationEnabled'],
    });
    const dialogProperties = reactive({
      unit: null,
      isUnitPauseEdit: false,
      isRented: false,
      lastShowingProfileId: null,
    });
    const actions = reactive({});

    const thumbnailUrl = computed(() => resolvePictureUrl(property.value));

    function getUnitCardStyle(unit) {
      const image = getUnitFirstImage(unit);
      return image ? { backgroundImage: `url(${image})` } : null;
    }

    return {
      property,
      thumbnailUrl,
      init,
      uiFlags,
      addUnitDialogClosed,
      get,
      getUnitPausedLabel,
      dialogProperties,
      actions,
      getUnitCardStyle,
      showBookAServiceDialog,
      showAddInquiryDialog,
      showUnitPauseDialog,
      showUnitUnpauseDialog,
      showUnitStopDialog,
      addInquiryDialogClosed,
      pauseDialogClosed,
      unpauseDialogClosed,
      stopDialogClosed,
      linkClicked,
      propertyRentedDialogClosed,
      showUnitDeleteDialog,
      unitDeleteDialogClosed,
      presentableImportedAt,
      computedUpcoming,
      computeActiveStatusText,
      computeIsShowingProfileNotPublishListing,
      resolveImportedType,
    };

    async function init() {
      property.value = await context.root.$store.dispatch(
        'Property/find',
        context.root.$route.params.id,
      );

      if (property.value.is_null_location) {
        redirectToUnitPage();
      }

      property.value.units = property.value.units.sort((a, b) => new Intl.Collator('en', { numeric: true }).compare(a.name, b.name));
      property.value.units.forEach((unit) => {
        const maxBudgetedTours = get(unit.active_showing_profile, 'max_budgeted_tours');
        const paidTourCount = get(unit.active_showing_profile, 'paid_tours_count');
        unit.openHouseToursCount = get(unit.active_showing_profile, 'open_house_completed_tours_count');

        if (!maxBudgetedTours || !paidTourCount) {
          unit.isTourBudgetWarning = false;
          return;
        }

        unit.isTourBudgetWarning = maxBudgetedTours - 2 <= paidTourCount && unit.status !== 'auto_paused';
      });

      actions.addUnit = {
        label: 'Add unit',
        visible: !uiFlags.isIntegrationEnabled,
        uiFlag: false,
        icon: 'sdicon-plus',
      };
    }

    function redirectToUnitPage() {
      const { id, units } = property.value;

      const redirectPage = {
        name: 'app.units.show',
        params: {
          id,
          unit_id: units[0].id,
        },
        query: { tab: 'listing' },
      };

      context.root.$router.push(redirectPage);
    }

    function resolveImportedType() {
      const type = capitalizeFirstLatter(getBusinessSourceType());
      const importedAt = presentableImportedAt(property.value.imported_at);
      return `Imported from ${type} on ${importedAt}`;
    }

    function computedUpcoming(unit) {
      const upcomingToursCount = get(unit, 'active_showing_profile.upcoming_tours_count', 0);
      const text = unit.active_showing_profile.is_open_house ? 'open house' : 'tour';
      const tourText = pluralize(text, upcomingToursCount);
      return `${upcomingToursCount} Upcoming ${tourText}`;
    }

    function computeIsShowingProfileNotPublishListing(unit) {
      return !isShowingProfilePublishListing(unit);
    }

    function computeActiveStatusText(unit) {
      const isPublishListing = isShowingProfilePublishListing(unit);
      const isOpenHouse = get(unit, 'active_showing_profile.is_open_house', false);

      if (isOpenHouse) {
        return Showing.STATUS_OPEN_HOUSE;
      }
      if (isPublishListing) {
        return Showing.STATUS_PUBLISH_LISTING;
      }
      return Showing.STATUS_SHOWING;
    }

    function isShowingProfilePublishListing(unit) {
      return get(unit, 'active_showing_profile.type') === Showing.TYPE_PUBLISH_LISTING;
    }

    async function addUnitDialogClosed(event) {
      uiFlags.isAddUnitDialogVisible = false;

      if (event?.status === 'success') {
        await init();
        showSuccessNotify(context, event.message);
      }

      if (event?.status === 'failure') {
        showErrorNotify(context, event.message);
      }
    }

    function linkClicked(event, unit) {
      if (get(context.refs, [`actionsDropDown-${unit.id}`, 0, 'visible'])) {
        event.preventDefault();
      }
    }

    function showBookAServiceDialog(unit) {
      dialogProperties.unit = unit;
      uiFlags.isBookAServiceDialogVisible = true;
    }

    function showAddInquiryDialog(unit) {
      dialogProperties.unit = {
        ...unit,
        property: property.value,
      };
      uiFlags.isAddInquiryDialogVisible = true;
    }

    function showUnitPauseDialog(unit, isEdit) {
      dialogProperties.unit = {
        ...unit,
        property: property.value,
      };
      dialogProperties.isUnitPauseEdit = isEdit;
      context.root.$nextTick(() => {
        uiFlags.isUnitPauseDialogVisible = true;
      });
    }

    function showUnitUnpauseDialog(unit) {
      dialogProperties.unit = {
        ...unit,
        property: property.value,
      };
      context.root.$nextTick(() => {
        uiFlags.isUnitUnpauseDialogVisible = true;
      });
    }

    function showUnitStopDialog(unit) {
      dialogProperties.unit = {
        ...unit,
        property: property.value,
      };
      context.root.$nextTick(() => {
        uiFlags.isUnitStopDialogVisible = true;
      });
    }

    function showUnitDeleteDialog(unit) {
      dialogProperties.unit = unit;
      uiFlags.isUnitDeleteDialogVisible = true;
    }

    async function addInquiryDialogClosed(status) {
      uiFlags.isAddInquiryDialogVisible = false;
      dialogProperties.unit = null;
      if (status === 'success') {
        await init();
      }
    }

    async function pauseDialogClosed(data) {
      const isEdit = dialogProperties.isUnitPauseEdit;
      uiFlags.isUnitPauseDialogVisible = false;
      setTimeout(() => {
        dialogProperties.isUnitPauseEdit = false;
      }, 500);
      if (data) {
        await init();
        let message = !isEdit ? 'Showing profile paused successfully' : 'Showing profile pause end time updated successfully';
        if (data.pauseFrom) {
          message = !isEdit ? 'Showing pause successfully scheduled' : 'Scheduled pause updated successfully';
        }
        showSuccessNotify(context, message);
      }
    }
    async function unpauseDialogClosed(status) {
      uiFlags.isUnitUnpauseDialogVisible = false;
      if (status === 'success') {
        await init();
        showSuccessNotify(context, 'Showing profile resumed successfully');
      }
    }

    async function stopDialogClosed(status, isRentedWithShowdigs) {
      uiFlags.isUnitStopDialogVisible = false;
      dialogProperties.lastShowingProfileId = dialogProperties.unit.last_showing_profile.id;
      dialogProperties.unit = {
        ...dialogProperties.unit,
        active_showing_profile: null,
      };
      if (status === 'success') {
        await init();
        showSuccessNotify(context, 'Showing profile deactivated successfully');
        dialogProperties.isRented = isRentedWithShowdigs;
        uiFlags.isPropertyRentedDialogVisible = true;
      }
    }

    function propertyRentedDialogClosed() {
      uiFlags.isPropertyRentedDialogVisible = false;
      if (!dialogProperties.unit.last_showing_profile.bundled_inspection && get(dialogProperties.unit.property.market, 'inspection_agents_available')) {
        uiFlags.isMoveInConditionReportPromptVisible = true;
      }
    }

    async function unitDeleteDialogClosed(status) {
      uiFlags.isUnitDeleteDialogVisible = false;
      if (status === 'success') {
        await init();
        showSuccessNotify(context, 'Unit deleted successfully', { dangerouslyUseHTMLString: true }, 'Property Deleted');
      }
    }
    function presentableImportedAt(importedAt) {
      return momentUtil(importedAt, null).toDisplayMonthAndDayFormat();
    }
  },
};
</script>

<style lang="scss">
#property-units-page {
  .picture {
    width: 72px;
    height: 72px;
    border-radius: $app-border-radius;
    background-image: url('../../assets/property-image-fallback-square.svg');
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gray-color(light);
  }

  .unit {
    height: 100%;
    border-radius: $app-border-radius;
    overflow: hidden;
    background: $white;
    color: gray-color('darker');
    display: flex;
    flex-direction: column;
    transition: $app-transition;

    &:hover {
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    }

    >.header {
      height: 71px;
      background-size: cover;
      background-position: center center;
      background-image: url('../../assets/unit-card-image.svg');
      position: relative;
      display: flex;
      flex-display: column;

      >.time-in-market {
        margin: 0.75rem;
        margin-right: 2.5rem;

        >div {
          color: $white;
          height: 1.5rem;
          background: rgba(23, 23, 23, 0.8);
          display: inline-flex;
          align-items: center;
          border-radius: 4px;
          padding: 0 0.25rem;

          >strong {
            font-size: 11px;
            margin-right: 0.25rem;
          }

          >span {
            font-size: 11px;
          }
        }
      }

      >.actions {
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        z-index: 1;
        background: $white;
        border-radius: 2px;
        text-align: center;
        transition: $transition-base;

        i {
          transition: $transition-base;
          font-size: $--font-size-medium-large;
          color: gray-color(dark);
          margin-top: -5px;
          margin-left: -2px;

          &:hover {
            color: gray-color(darker);
          }

          &:before {
            margin: 0;
            transform: translateY(-3.5px);
          }
        }
      }
    }

    >.body {
      padding: 1rem;
      flex: 1;

      >.inquiries-tours-count {
        i.sdicon-exclamation-triangle:before {
          margin: 0;
        }
      }
    }

    >.footer {
      min-height: 57px;
      padding: 0.75rem 1rem;
      border-top: 1px solid gray-color(light);
      display: flex;
      align-items: center;

      >.status {
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        color: $white;
        display: inline-block;
        font-size: $--font-size-small;
        font-weight: bold;
        text-transform: uppercase;

        &.active {
          background: theme-color(success);
        }

        &.paused {
          background: theme-color(danger);
        }

        &.inactive {
          background: gray-color();
        }
      }

      >.upcoming-tours {
        font-size: 11px;
        margin-left: 0.5rem;
        width: 7rem;
        text-align: center;

        &::before {
          content: '';
          width: 3px;
          height: 3px;
          border-radius: 50%;
          margin: 2px 0.25rem 2px 0;
          display: inline-block;
          background: gray-color('darker');
        }
      }

      .imported-at {
        width: 9rem;
        padding-left: 0.5rem;
        line-height: 15px;
      }
    }
  }

  .back-action {
    position: absolute;
    left: -50px;

    @media (max-width: 1439px) {
      position: relative;
      left: unset;
    }
  }
}
</style>
